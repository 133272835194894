import axios from "axios";

export default {
    state: {
        parcel: {},
        parcelOrders: [],
        sentParcels: [],
        receivedParcels: [],
        awaitingShipmentParcels: {},
        orderServices: [],
        parcelServices: [],
        orderInvoices: [],
        ordersInWarehouse: [],
        orderInvoiceUploadResponse: null,
        orderDeleteInvoiceResponse: null,
        orderProductCategories: [],
        parcelRedirectUrl: null,
    },
    mutations: {
        setParcel(state, payload) {
            state.parcel = payload;
        },
        setParcelOrders(state, payload) {
            state.parcelOrders = payload;
        },
        setSentParcels(state, payload) {
            state.sentParcels = payload;
        },
        setReceivedParcels(state, payload) {
            state.receivedParcels = payload;
        },
        setAwaitingShipmentParcels(state, payload) {
            state.awaitingShipmentParcels = payload;
        },
        setOrderServices(state, payload) {
            state.orderServices = payload;
        },
        setParcelServices(state, payload) {
            state.parcelServices = payload;
        },
        setOrderInvoices(state, payload) {
            state.orderInvoices = payload;
        },
        setOrderInvoiceUploadResponse(state, payload) {
            state.orderInvoiceUploadResponse = payload;
        },
        setOrderDeleteInvoiceResponse(state, payload) {
            state.orderDeleteInvoiceResponse = payload;
        },
        setOrderProductCategories(state, payload) {
            state.orderProductCategories = payload;
        },
        setOrderInWarehouse(state, payload) {
            state.ordersInWarehouse = payload;
        },
        setParcelRedirectUrl(state, payload) {
            state.parcelRedirectUrl = payload;
        }
    },
    getters:{
        getParcel(state) {
            return state.parcel;
        },
        getOrdersInParcel(state) {
            return state.parcelOrders;
        },
        getSentParcels(state) {
            return state.sentParcels;
        },
        getReceivedParcels(state) {
            return state.receivedParcels;
        },
        getAwaitingShipmentParcels(state) {
            return state.awaitingShipmentParcels;
        },
        getOrderServices(state) {
            return state.orderServices;
        },
        getParcelServices(state) {
            return state.parcelServices;
        },
        getOrderInvoices(state) {
            return state.orderInvoices;
        },
        getOrderInvoiceUploadResponse(state) {
            return state.orderInvoiceUploadResponse;
        },
        getOrderDeleteInvoiceResponse(state) {
            return state.orderDeleteInvoiceResponse;
        },
        getOrderProductCategories(state) {
            return state.orderProductCategories;
        },
        getOrdersInWarehouse(state) {
            return state.ordersInWarehouse;
        },
        getParcelRedirectUrl(state) {
            return state.parcelRedirectUrl;
        }
    },
    actions: {
        async fetchParcel({commit}, id) {
            try {
                const response = await axios.get('/user-dashboard/packages/parcel/' + id +'?request=ajax');
                commit('setParcel', response.data);
            } catch (e) {
                console.log(e);
            }
        },
        async fetchPackagingParcel({commit}, id) {
            try {
                const response = await axios.get('/user-dashboard/packages/packaging/' + id +'?request=ajax');
                commit('setParcel', response.data);
            } catch (e) {
                console.log(e);
            }
        },
        async fetchSentParcels({commit}, page) {
            try {
                let url = '/user-dashboard/packages/sent?request=ajax';

                if(page) url += '&page=' + page;

                const response = await axios.get(url);
                commit('setSentParcels', response.data);
            } catch (e) {
                console.log(e);
            }
        },
        async fetchReceivedParcels({commit}, page) {
            try {
                let url = '/user-dashboard/packages/received?request=ajax';

                if(page) url += '&page=' + page;

                const response = await axios.get(url);
                commit('setReceivedParcels', response.data);
            } catch (e) {
                console.log(e);
            }
        },
        async fetchOrdersInParcel({commit}, id) {
            try {
                const response = await axios.get('/user-dashboard/packages/orders-in-parcel/' + id);
                commit('setParcelOrders', response.data);
            } catch (e) {
                console.log(e);
            }
        },
        async fetchAwaitingShipmentParcels({commit}) {
            try {
                const response = await axios.get('/user-dashboard/packages/awaiting-shipment?request=ajax');
                commit('setAwaitingShipmentParcels', response.data);
            } catch (e) {
                console.log(e);
            }
        },
        async fetchOrderServices({commit}, type) {
            try {
                const url = type ? '/user-dashboard/packages/services?type=' + type : '/user-dashboard/packages/services';
                const response = await axios.get(url);
                commit('setOrderServices', response.data);
            } catch (e) {
                console.log(e);
            }
        },
        async fetchParcelServices({commit}) {
            try {
                const response = await axios.get('/user-dashboard/packages/parcel-services');
                commit('setParcelServices', response.data);
            } catch (e) {
                console.log(e);
            }
        },
        async fetchOrdersInWarehouse({commit}, parcelId = null) {
            try {
                const response = await axios.get(`/user-dashboard/packages/in-warehouse-orders/${parcelId}?request=ajax`);
                commit('setOrderInWarehouse', response.data);
            } catch (e) {
                console.log(e);
            }
        },
        async fetchOrderInvoices({commit}, id) {
            try {
                const response = await axios.get('/user-dashboard/packages/invoices/' + id);
                commit('setOrderInvoices', response.data);
            } catch (e) {
                console.log(e);
            }
        },
        async createOrder({commit}, payload) {
            try {
                await axios.post('/user-dashboard/packages/create-order' ,payload);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async editOrder({commit}, payload) {
            try {
                await axios.post('/user-dashboard/packages/edit/' + payload.id ,payload.data)
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async deleteOrder({ commit }, id) {
            try {
                await axios.delete('/user-dashboard/packages/delete/' + id);
            } catch (error) {
                console.error('Error deleting order:', error);
                throw error;
            }
        },
        async uploadInvoice({ commit }, payload) {
            try {
                const formData = new FormData();
                formData.append('file', payload.file);
                formData.append('order_id', payload.order_id);

                const response = await axios.post('/user-dashboard/packages/upload-invoice', formData);

                commit('setOrderInvoiceUploadResponse', response.data);
            } catch (error) {
                console.error('Error uploading invoice:', error);
                throw error;
            }
        },
        async deleteInvoice({ commit }, id) {
            try {
                const response = await axios.delete('/user-dashboard/packages/delete-invoice/' + id);

                commit('setOrderDeleteInvoiceResponse', response.data);
            } catch (error) {
                console.error('Error deleting invoice:', error);
                throw error;
            }
        },
        async fetchOrderProductCategories({commit}) {
            try {
                const response = await axios.get('/user-dashboard/packages/product-categories');

                commit('setOrderProductCategories', response.data);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async storeOrderDeclaration({commit}, payload) {
            try {
                await axios.post('/user-dashboard/packages/store-order-declaration', payload);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        storeOrderComment({commit}, payload) {
            try {
                return axios.post('/user-dashboard/packages/store-order-comment', payload);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async storeOrderServices({commit}, payload) {
            try {
                await axios.post('/user-dashboard/packages/store-order-services', payload);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async createParcel({commit}, payload) {
            try {
                const url = payload.id ? `/user-dashboard/packages/store-parcel/${payload.id}` : '/user-dashboard/packages/store-parcel';
                const response = await axios.post(url, payload);

                commit('setParcelRedirectUrl', response.data);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async removeOrderFromParcel({commit}, id) {
            try {
                await axios.get(`/user-dashboard/packages/remove-order-from-parcel/${id}`);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async packTheParcel({commit}, payload) {
            try {
                const response = await axios.post('/user-dashboard/packages/pack-the-parcel', payload);

                commit('setParcelRedirectUrl', response.data);
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async payShipment({commit}, payload) {
            try {
                const response = await axios.post('/user-dashboard/packages/pay-shipment', payload);

                commit('setParcelRedirectUrl', response.data);
            } catch (e) {
                console.log(e);
                throw e;
            }
        }
    }
};
